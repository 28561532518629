import React from "react"
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types'

const ImageBackground = styled(BackgroundImage)`
  background-position: center;
  background-size: cover;
  height: 50vh;

  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`;

const Banner = ({
  fluid,
  alt,
  heading,
  subheading
}) => {

  return (
    <ImageBackground Tag="section" fluid={fluid} alt={alt} fadeIn="soft">
        <div className="container pt-4">
          <h1 className="display-2 text-light"><strong>{heading}</strong></h1>
          <h2 className="display-5 text-light"><strong>{subheading}</strong></h2>
        </div>
    </ImageBackground>
  );
};

Banner.propTypes = {
  fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

export default Banner